@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0%;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(241, 241, 241); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'),
    url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}

@layer utilities {

  .shadow-extra-small {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .shadow-small {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  .shadow-medium {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .shadow-large {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .shadow-xlarge {
    box-shadow: 0 15px 15px 15px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .intContainer {
    width: 80%;
    max-width: 900px;
    /* Set maximum width */
    margin: 0 auto;
    /* padding-right: 20rem;    
    padding-left: 20rem;  */
  }

  .poppins-regular {
    font-family: Poppins-Regular, sans-serif;
  }

  .poppins-semibold {
    font-family: Poppins-SemiBold, sans-serif;
  }

  .poppins-medium {
    font-family: Poppins-Medium, sans-serif;
  }

  .montserrat-regular {
    font-family: Montserrat-Regular, sans-serif;
  }

  .op {
    color: #95b1eb;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #0D0630;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #0D0630;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #0D0630;
  }

  .custom-scrollbar {
    scrollbar-width:none;
    scrollbar-color: rgb(13, 6, 48) #cbd5e0;
  }

  /* .home {
    background: #1433cc;
    background: -webkit-linear-gradient(0deg, #1433cc 0%, #000447 100%);
    background: linear-gradient(0deg, #1433cc 0%, #000447 100%);
  } */

  .zindex{
    z-index: 100;
  }

  .form-container {

    background: rgba(255, 255, 255, 0.09);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(50%);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .perf-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgb(19, 18, 18);
    filter: blur(20rem);
}

.outer {
  border: 1px solid rgb(51, 50, 50);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  place-content: center;
  align-items: center;
  background-image: conic-gradient(blue 95%, #181924 0%);
  margin-top: 5px;
  /* box-shadow: 6px 6px  10px -1px rgb(22, 21, 21), -6px -6px  10px -1px rgb(22, 21, 21); */
}

.inner {
  border: 1px solid rgb(51, 50, 50);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  place-content: center;
  background-color: white;
  position: relative;
  left: 0;
  top: 0;
}

.file-label{    
  border: 2px solid grey;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  /* box-shadow: 17px 28px 53px -17px rgba(0, 0, 0, 0.7); */
  color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  
}

.file-label p {
  text-align: center;
}

/* .file-input{
  display: none;
} */



.file-cont-second{
  margin-top: 2rem;
  background-color: grey;
  border-radius: 10px;
  width: 50%; 
  padding: 20px 0 20px 0;
  /* height: 15.5rem; */
  border: 2px soild red;
}

.file-flex{
  display: flex;
  place-content: center;
  align-items: center;
}

.file-button{
  display: flex;
  place-content: center;
  /* margin-top: 5px; */
}

.file-button button {
  background-color: blue;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  color: white;
  padding: 8px 0px 8px 0px;  
  width: 100%;
}

.fa-upload{
  color: white;
  position: relative;
  top: -1.7rem;
  left: 42%; 

}



.file-drag {
  /* background-color: white; */
  background-color: rgb(223, 219, 219);
  color: black;
}

.file-drag-img{
  display: flex;
  place-content: center;
  align-items: center;
  border-radius: 5px;
}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
  background: rgb(19, 18, 18); 
  filter: blur(40rem);
}

.modal-modal {
    
  border-radius: 20px;
  z-index: 100;
  overflow: hidden;
  color: black;
  padding: 10px;
}

.modal-modal2{
  border-radius: 20px;
  z-index: 100;
  color: black;
  padding: 10px;
}

.modal-moal-login{
  z-index: 100;
  overflow: hidden;
  color: black;
  
}

/* .table-headers{ 
  padding: 8px;
  text-align: center;
  height: 8vh; 
  color: #2c7cf5;
  font-weight:bolder;
 
} */

/* .table-header-radius{
  border: 2px solid red;
} */

/* .table-headers th{
  font-size: 12px;
  padding:0 5px 0 5px; 
} */






.table-container{
  margin-top: 3%;
  display: flex;
  place-content: center;
  
  
}

.table-data{
  text-align: center;
  border: 1px Solid white;
  font-size: small;
  
}



}